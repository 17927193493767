import {
    GET_COUNTRY_INDICATOR_DETAILS,
    GET_COUNTRY_INDICATOR_DETAILS_SUCCESS,
    GET_COUNTRY_INDICATOR_DETAILS_FAILURE,
    RESET_COUNTRY_INDICATOR_DETAILS,

    GET_COUNTRY_VICE_INDICATOR_LIST,
    GET_COUNTRY_VICE_INDICATOR_LIST_SUCCESS,
    GET_COUNTRY_VICE_INDICATOR_LIST_FAILURE,

    GET_COUNTRY_VICE_INDICATOR_LIST_SECOND,
    GET_COUNTRY_VICE_INDICATOR_LIST_SECOND_SUCCESS,
    GET_COUNTRY_VICE_INDICATOR_LIST_SECOND_FAILURE,

    GET_DATA_ANALYSIS_DATA,
    GET_DATA_ANALYSIS_DATA_SUCCESS,
    GET_DATA_ANALYSIS_DATA_FAILURE,

    MARK_CHART_FAVOURITE,
    MARK_CHART_FAVOURITE_SUCCESS,
    MARK_CHART_FAVOURITE_FAILURE,

    GET_COUNTRY_DETAILS_GRAPH,
    GET_COUNTRY_DETAILS_GRAPH_SUCCESS,
    GET_COUNTRY_DETAILS_GRAPH_FAILURE,
    RESET_COUNTRY_DETAILS_GRAPH,

    GET_COUNTRY_INDICATOR_WISE,
    GET_COUNTRY_INDICATOR_WISE_SUCCESS,
    GET_COUNTRY_INDICATOR_WISE_FAILURE,

    STORE_COUNTRY_DATA,
    RESET_STORE_COUNTRY_DATA
} from './types'

export const getCountryIndicatorDetails = (payload) => ({
    type: GET_COUNTRY_INDICATOR_DETAILS,
    payload
});

export const getCountryIndicatorDetailsSuccess = (payload) => ({
    type: GET_COUNTRY_INDICATOR_DETAILS_SUCCESS,
    payload
});

export const getCountryIndicatorDetailsFailure = (payload) => ({
    type: GET_COUNTRY_INDICATOR_DETAILS_FAILURE,
    payload
});

export const resetCountryIndicatorDetails = () => ({
    type: RESET_COUNTRY_INDICATOR_DETAILS
});

export const getCountryViceIndicatorList = payload => ({
   type: GET_COUNTRY_VICE_INDICATOR_LIST,
   payload
});

export const getCountryViceIndicatorListSuccess = payload => ({
    type: GET_COUNTRY_VICE_INDICATOR_LIST_SUCCESS,
    payload
});

export const getCountryViceIndicatorListFailure = payload => ({
    type: GET_COUNTRY_VICE_INDICATOR_LIST_FAILURE,
    payload
});

export const getCountryViceIndicatorListSecond = payload => ({
    type: GET_COUNTRY_VICE_INDICATOR_LIST_SECOND,
    payload
});

export const getCountryViceIndicatorListSecondSuccess = payload => ({
    type: GET_COUNTRY_VICE_INDICATOR_LIST_SECOND_SUCCESS,
    payload
});

export const getCountryViceIndicatorListSecondFailure = payload => ({
    type: GET_COUNTRY_VICE_INDICATOR_LIST_SECOND_FAILURE,
    payload
});

export const getDataAnalysisData = payload => ({
    type: GET_DATA_ANALYSIS_DATA,
    payload
});

export const getDataAnalysisDataSuccess = payload => ({
    type: GET_DATA_ANALYSIS_DATA_SUCCESS,
    payload
});

export const getDataAnalysisDataFailure = payload => ({
    type: GET_DATA_ANALYSIS_DATA_FAILURE,
    payload
});

export const markChartAsFavourite = payload => ({
    type: MARK_CHART_FAVOURITE,
    payload
});

export const markChartAsFavouriteSuccess = () => ({
    type: MARK_CHART_FAVOURITE_SUCCESS
});

export const markChartAsFavouriteFailure = () => ({
    type: MARK_CHART_FAVOURITE_FAILURE
});

export const getCountryDetailsGraph = payload => ({
    type: GET_COUNTRY_DETAILS_GRAPH,
    payload
});

export const getCountryDetailsGraphSuccess = payload => ({
    type: GET_COUNTRY_DETAILS_GRAPH_SUCCESS,
    payload
});

export const getCountryDetailsGraphFailure = payload => ({
    type: GET_COUNTRY_DETAILS_GRAPH_FAILURE,
    payload
});

export const resetCountryDetailsGrpah = () => ({
   type: RESET_COUNTRY_DETAILS_GRAPH
});

export const getCountryIndicatorWise = (payload) => ({
    type: GET_COUNTRY_INDICATOR_WISE,
    payload
});

export const getCountryIndicatorWiseSuccess = (payload) => ({
    type: GET_COUNTRY_INDICATOR_WISE_SUCCESS,
    payload
});

export const getCountryIndicatorWiseFailure = (payload) => ({
    type: GET_COUNTRY_INDICATOR_WISE_FAILURE,
    payload
});

export const setCountryData = payload => ({
    type: STORE_COUNTRY_DATA,
    payload
});

export const resetCountryData = () => ({
    type: RESET_STORE_COUNTRY_DATA
})