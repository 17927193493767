import { all, takeEvery, put, call } from 'redux-saga/effects';
import {toast} from "react-toastify";

import { REGISTER_USER } from '../action/types';
import { registerUserSuccess, registerUserFailure } from '../action';
import API from '../../utils/api';

function* registerUserRequest(action) {
    const { email, password, name, country_id, image, callback} = action.payload;
    const bodyFormData = new FormData();
    bodyFormData.append('email', email)
    bodyFormData.append('password', password)
    bodyFormData.append('name', name)
    bodyFormData.append('country_id', country_id)
    image && bodyFormData.append('image', image)
    try {
        const res = yield API.post('register', bodyFormData);
        if(res.data.meta.code === 1){
            yield put(registerUserSuccess());
            toast('Verification mail is sent to your email address. please verify and Login.');
            yield call(callback);
        } else if(res.data.meta.code === 0) {
            yield put(registerUserFailure());
            toast(res.data.meta.message);
        }
    }catch (e) {
        yield put(registerUserFailure());
    }
}

export function* watchRegisterUserAPI() {
    yield takeEvery(REGISTER_USER, registerUserRequest);
}

export default function* rootSaga() {
    yield all([
        watchRegisterUserAPI()
    ]);
}