import { all, takeEvery, put } from 'redux-saga/effects';

import {GET_COUNTRY_DETAILS} from '../action/types';
import {getCountryDetailsSuccess, getCountryDetailsFailure} from '../action';
import API from '../../utils/api';
import {toast} from "react-toastify";

function* countryDetailsRequest(action) {
    try {
        const res = yield API.post(`country-detail/${action.payload}`);
        if(res.data.meta.code === 1) {
            yield put(getCountryDetailsSuccess(res.data.data));
        } else if(res.data.meta.code === 0) {
            yield put(getCountryDetailsFailure(res.data));
            toast(res.data.meta.message)
        }
    } catch (e) {
        yield put(getCountryDetailsFailure(e));
    }
}

export function* watchCountryDetailsAPI() {
    yield takeEvery(GET_COUNTRY_DETAILS, countryDetailsRequest);
}

export default function* rootSaga() {
    yield all([
        watchCountryDetailsAPI()
    ]);
}