import { all, takeEvery, put } from 'redux-saga/effects';

import {GET_MY_CHARTS_DATA} from '../action/types';
import {
    getMyChartsDataSuccess,
    getMyChartsDataFailure,
} from '../action';
import API from '../../utils/api';

function* myChartsDataRequest() {
    try {
        const res = yield API.get('favourite-graph');
        if (res.data.meta.code === 1) {
            yield put(getMyChartsDataSuccess(res.data.data));
        }
    } catch (e) {
        yield put(getMyChartsDataFailure(e));
    }
}

export function* watchGetMyChartsDataAPI() {
    yield takeEvery(GET_MY_CHARTS_DATA, myChartsDataRequest);
}

export default function* rootSaga() {
    yield all([
        watchGetMyChartsDataAPI(),
    ])
}