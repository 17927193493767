import {
    GET_TRENDING_DATA,
    GET_TRENDING_DATA_SUCCESS,
    GET_TRENDING_DATA_FAILURE,

    GET_AUTO_SEARCH_LIST_SUCCESS,
    GET_AUTO_SEARCH_LIST_FAILURE,
    RESET_AUTO_SEARCH,

    GET_QUICK_SEARCH_DATA,
    GET_QUICK_SEARCH_DATA_SUCCESS,
    GET_QUICK_SEARCH_DATA_FAILURE,
    RESTE_QUICK_SEARCH_DATA,

    GET_NOTIFICATION_DATA,
    GET_NOTIFICATION_DATA_SUCCESS,
    GET_NOTIFICATION_DATA_FAILURE,

    GET_RELATED_DATA,
    GET_RELATED_DATA_SUCCESS,
    GET_RELATED_DATA_FAILURE,

    GET_RANDOM_SUGGESTION_SEARCH_DATA,
    GET_RANDOM_SUGGESTION_SEARCH_DATA_SUCCESS,
    GET_RANDOM_SUGGESTION_SEARCH_DATA_FAILURE,
    RESET_RANDOM_SEARCH
} from '../action/types';

const INIT_STATE = {
    loading: false,
    data: [],
    autoSearchList: null,
    quickSearchData: {},
    notificationData: [],
    pagination: {},
    relatedData: [],
    randomSuggestionData: [],
    trendingLoading: false,
    randomSearchLoading: false
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_TRENDING_DATA:
            return { ...state, trendingLoading: true };
        case GET_TRENDING_DATA_SUCCESS:
            return { ...state, trendingLoading: false, data: action.payload };
        case GET_TRENDING_DATA_FAILURE:
            return { ...state, trendingLoading: false, data: action.payload };
        case GET_AUTO_SEARCH_LIST_SUCCESS:
            return { ...state, autoSearchList: action.payload };
        case GET_AUTO_SEARCH_LIST_FAILURE:
            return { ...state, autoSearchList: action.payload };
        case RESET_AUTO_SEARCH:
            return { ...state, autoSearchList: [] };
        case GET_QUICK_SEARCH_DATA:
            return { ...state, loading: true };
        case GET_QUICK_SEARCH_DATA_SUCCESS:
            return { ...state, loading: false, quickSearchData: action.payload };
        case GET_QUICK_SEARCH_DATA_FAILURE:
            return { ...state, loading: false, quickSearchData: action.payload };
        case RESTE_QUICK_SEARCH_DATA:
            return { ...state, quickSearchData: {} };
        case GET_NOTIFICATION_DATA:
            return { ...state, loading: true };
        case GET_NOTIFICATION_DATA_SUCCESS:
            return { ...state, loading: false, notificationData: state.notificationData.concat(action.payload.data), pagination: action.payload.meta };
        case GET_NOTIFICATION_DATA_FAILURE:
            return { ...state, loading: false, notificationData: action.payload };
        case GET_RELATED_DATA:
            return { ...state, loading: true };
        case GET_RELATED_DATA_SUCCESS:
            return { ...state, loading: false, relatedData: action.payload };
        case GET_RELATED_DATA_FAILURE:
            return { ...state, loading: false, relatedData: action.payload };
        case GET_RANDOM_SUGGESTION_SEARCH_DATA:
            return { ...state, randomSearchLoading: true };
        case GET_RANDOM_SUGGESTION_SEARCH_DATA_SUCCESS:
            return { ...state, randomSearchLoading: false, randomSuggestionData: action.payload };
        case GET_RANDOM_SUGGESTION_SEARCH_DATA_FAILURE:
            return { ...state, randomSearchLoading: false, randomSuggestionData: action.payload };
        case RESET_RANDOM_SEARCH:
            return { ...state, loading: false, randomSuggestionData: [] };
        default:
            return state;
    }
};