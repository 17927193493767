import { all, takeEvery, put, select } from 'redux-saga/effects';
import {toast} from "react-toastify";

import {
    GET_COUNTRY_INDICATOR_DETAILS,
    GET_COUNTRY_VICE_INDICATOR_LIST,
    GET_COUNTRY_VICE_INDICATOR_LIST_SECOND,
    GET_DATA_ANALYSIS_DATA,
    MARK_CHART_FAVOURITE,
    GET_COUNTRY_DETAILS_GRAPH,
    GET_COUNTRY_INDICATOR_WISE
 } from '../action/types';
import {
    getCountryIndicatorDetailsSuccess,
    getCountryIndicatorDetailsFailure,
    getCountryViceIndicatorListSuccess,
    getCountryViceIndicatorListFailure,
    getCountryViceIndicatorListSecondSuccess,
    getCountryViceIndicatorListSecondFailure,
    getDataAnalysisData,
    getDataAnalysisDataSuccess,
    getDataAnalysisDataFailure,
    markChartAsFavouriteSuccess,
    markChartAsFavouriteFailure,
    getCountryDetailsGraphSuccess,
    getCountryDetailsGraphFailure,
    getCountryIndicatorWiseSuccess,
    getCountryIndicatorWiseFailure,
    getMyChartsData,
    setCountryData
} from '../action';
import API from '../../utils/api';
import {getToken} from "../../utils/helper";

function* getCountryIndicatorDetailsRequest(action) {
    try {
        const url = getToken() ? 'country-indicator-search' : 'compare-search';
        const res = yield API.post(url, action.payload);
        if(res.data.meta.code === 1){
            yield put(getCountryIndicatorDetailsSuccess(res.data.data));
            if(getToken()) {
                const data = [];
                if (res.data.data.first_search && !res.data.data.second_search) {
                    data.push({
                        country_id: res.data.data.first_search.country_id,
                        graph_id: res.data.data.first_search.graph_id
                    });
                    yield put(setCountryData({
                        country_id: res.data.data.first_search.country_id,
                        graph_id: res.data.data.first_search.graph_id
                    }))
                    yield put(getDataAnalysisData({
                        analysis: data
                    }));
                } else if (res.data.data.second_search && res.data.data.first_search) {
                    data.push({
                        country_id: res.data.data.first_search.country_id,
                        graph_id: res.data.data.first_search.graph_id
                    },{
                        country_id: res.data.data.second_search.country_id,
                        graph_id: res.data.data.second_search.graph_id
                    });
                    yield put(getDataAnalysisData({
                        analysis: data
                    }));
                }
            }
        }else if(res.data.meta.code === 0) {
            yield put(getCountryIndicatorDetailsFailure(res.data));
            toast(res.data.meta.message);
        }
    }catch (e) {
        yield put(getCountryIndicatorDetailsFailure(e));
    }
}

function* getCountryViceIndicatorListRequest(action) {
    try {
        const res = yield API.get(`indicator-list/${action.payload}`);
        if(res.data.meta.code === 1){
            yield put(getCountryViceIndicatorListSuccess(res.data.data));
        }else if(res.data.meta.code === 0){
            yield put(getCountryViceIndicatorListFailure(res));
        }
    }catch (e) {
        yield put(getCountryViceIndicatorListFailure(e));
    }
}

function* getCountryViceIndicatorListSecondRequest(action) {
    try {
        const res = yield API.get(`indicator-list/${action.payload}`);
        if(res.data.meta.code === 1){
            yield put(getCountryViceIndicatorListSecondSuccess(res.data.data));
        }else if(res.data.meta.code === 0){
            yield put(getCountryViceIndicatorListSecondFailure(res));
        }
    }catch (e) {
        yield put(getCountryViceIndicatorListSecondFailure(e));
    }
}

function* getDataAnalysisDataRequest(action) {
    try {
        const res = yield API.post('analytic-data', action.payload);
        if(res.data.meta.code === 1){
            yield put(getDataAnalysisDataSuccess(res.data.data.analysisArray));
        }
    }catch (e) {
        yield put(getDataAnalysisDataFailure(e));
    }
}

function* markChartAsFavouriteRequest(action) {
    try {
        const res = yield API.post('add-favourite-graph', action.payload);
        if(res.data.meta.code === 1){
            yield put(markChartAsFavouriteSuccess());
            yield put(getMyChartsData());
            toast(res.data.meta.message)
        }
    } catch (e) {
        yield put(markChartAsFavouriteFailure());
    }
}

function* getCountryDetailsGraphRequest(action) {
    try {
        const url = getToken() ? 'country-indicator-search' : 'compare-search';
        const res = yield API.post(url, action.payload);
        if(res.data.meta.code === 1){
            yield put(getCountryDetailsGraphSuccess(res.data.data));
            yield put(setCountryData({
                country_id: res.data.data.first_search.country_id,
                graph_id: res.data.data.first_search.graph_id
            }))
            const getDataForAnalysis = state => state.CountryIndicator.dataForAnalysis
            const dataForAnalysis = yield select(getDataForAnalysis)
            if(getToken()) {
                yield put(getDataAnalysisData({
                    analysis: dataForAnalysis
                }));
            }
        }
    }catch (e) {
        yield put(getCountryDetailsGraphFailure(e));
    }
}

function* getCountryIndicatorWiseRequest(action) {
    try {
        const res = yield API.post('country', action.payload);
        if(res.data.meta.code === 1) {
            yield put(getCountryIndicatorWiseSuccess(res.data.data));
        }
    }catch (e) {
        yield put(getCountryIndicatorWiseFailure(e));
    }
}

export function* watchCountryIndicatorDetailsAPI() {
    yield takeEvery(GET_COUNTRY_INDICATOR_DETAILS, getCountryIndicatorDetailsRequest);
}

export function* watchGetCountryViceIndicatorListAPI() {
    yield takeEvery(GET_COUNTRY_VICE_INDICATOR_LIST, getCountryViceIndicatorListRequest);
}

export function* watchGetCountryViceIndicatorListSecondAPI() {
    yield takeEvery(GET_COUNTRY_VICE_INDICATOR_LIST_SECOND, getCountryViceIndicatorListSecondRequest);
}

export function* watchGetDataAnalysisDataAPI() {
    yield takeEvery(GET_DATA_ANALYSIS_DATA, getDataAnalysisDataRequest)
}

export function* watchMarkChartAsFavouriteAPI() {
    yield takeEvery(MARK_CHART_FAVOURITE, markChartAsFavouriteRequest)
}

export function* watchGetCountryDetailsGraphAPI() {
    yield takeEvery(GET_COUNTRY_DETAILS_GRAPH, getCountryDetailsGraphRequest)
}

export function* watchGetCountryIndicatorWiseAPI() {
    yield takeEvery(GET_COUNTRY_INDICATOR_WISE, getCountryIndicatorWiseRequest)
}

export default function* rootSaga() {
    yield all([
        watchCountryIndicatorDetailsAPI(),
        watchGetCountryViceIndicatorListAPI(),
        watchGetCountryViceIndicatorListSecondAPI(),
        watchGetDataAnalysisDataAPI(),
        watchMarkChartAsFavouriteAPI(),
        watchGetCountryDetailsGraphAPI(),
        watchGetCountryIndicatorWiseAPI()
    ]);
}