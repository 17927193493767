import { all } from 'redux-saga/effects';

import Login from './loginSaga';
import Register from './registerSaga';
import ForgotPassword from './forgotPasswordSaga';
import Support from './supportSaga';
import ResetPassword from './resetPasswordSaga';
import Careers from './careersSaga';
import Faq from './faqSaga';
import CategoryAndIndicators from './categoryAndIndicatorsSaga';
import Countries from './countriesSaga';
import MyProfile from './myProfileSaga';
import Home from './homeSaga';
import CountryDashboard from './countryDashboardSaga';
import RandomSearch from './randomSearchSaga';
import CountryIndicator from './countryIndicatorSaga';
import MyCharts from "./myChartsSaga";
import AboutUs from './aboutUsSaga';
import CMS from './cmsPageSaga';

export default function* rootSaga() {
    yield all([
        Login(),
        Register(),
        ForgotPassword(),
        Support(),
        ResetPassword(),
        Careers(),
        CategoryAndIndicators(),
        Faq(),
        Countries(),
        MyProfile(),
        Home(),
        CountryDashboard(),
        RandomSearch(),
        CountryIndicator(),
        MyCharts(),
        AboutUs(),
        CMS()
    ]);
}