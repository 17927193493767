import {
    GET_RANDOM_SEARCH_DATA,
    GET_RANDOM_SEARCH_DATA_SUCCESS,
    GET_RANDOM_SEARCH_DATA_FAILURE,

    GET_ADD_ON_DATA,
    GET_ADD_ON_DATA_SUCCESS,
    GET_ADD_ON_DATA_FAILURE,
    RESET_ADD_ON_DATA
} from '../action/types';

const INIT_STATE = {
    loading: false,
    data: {},
    addOnData: {}
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_RANDOM_SEARCH_DATA:
            return {...state, loading: true};
        case GET_RANDOM_SEARCH_DATA_SUCCESS:
            return {...state, loading: false, data: action.payload};
        case GET_RANDOM_SEARCH_DATA_FAILURE:
            return {...state, loading: false, data: action.payload};
        case GET_ADD_ON_DATA:
            return {...state, loading: true};
        case GET_ADD_ON_DATA_SUCCESS:
            return {...state, loading: false, addOnData: action.payload};
        case GET_ADD_ON_DATA_FAILURE:
            return {...state, loading: false, addOnData: action.payload};
        case RESET_ADD_ON_DATA:
            return { ...state, addOnData: {} };
        default:
            return state;
    }
};