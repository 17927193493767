import React from 'react';
import PropTypes from 'prop-types';

const Button = ({type, className, onClick, buttonText, disabled}) => {
    return (
        <button
            type={type}
            className={className}
            onClick={onClick}
            disabled={disabled}
        >
            {buttonText}
        </button>
    );
};

Button.propTypes = {
    type: PropTypes.string,
    className: PropTypes.string,
    onClick: PropTypes.func,
    buttonText: PropTypes.string,
    disabled: PropTypes.bool
};

export default Button;