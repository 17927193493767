import React from 'react';
import PropTypes from 'prop-types';

const CheckBoxWithLabel = ({name, checked, onChange, label}) => {
    return (
        <>
            <input
                type="checkbox"
                id={name}
                name={name}
                checked={checked}
                onChange={onChange}
            />
            <label htmlFor={name} className='f-14 text-grey'>
                {label}
            </label>
        </>
    );
};

CheckBoxWithLabel.propTypes = {
    name: PropTypes.string,
    checked: PropTypes.bool,
    onChange: PropTypes.func,
    label: PropTypes.string
};

export default CheckBoxWithLabel;