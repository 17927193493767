// =====================Login===========================

export const LOGIN_USER = 'LOGIN_USER';
export const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS';
export const LOGIN_USER_FAILURE = 'LOGIN_USER_FAILURE';

// =====================Register===========================

export const REGISTER_USER = 'REGISTER_USER';
export const REGISTER_USER_SUCCESS = 'REGISTER_USER_SUCCESS';
export const REGISTER_USER_FAILURE = 'REGISTER_USER_FAILURE';

// ====================Forgot password==================

export const FORGOT_PASSWORD = 'FORGOT_PASSWORD';
export const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS';
export const FORGOT_PASSWORD_FAILURE = 'FORGOT_PASSWORD_FAILURE';

// ====================Support Page==================

export const SUPPORT_FORM = 'SUPPORT_FORM';
export const SUPPORT_FORM_SUCCESS = 'SUPPORT_FORM_SUCCESS';
export const SUPPORT_FORM_FAILURE = 'SUPPORT_FORM_FAILURE';

// ====================Reset password==================

export const RESET_PASSWORD='RESET_PASSWORD';
export const RESET_PASSWORD_SUCCESS='RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_FAILURE='RESET_PASSWORD_FAILURE';

// ====================Careers==================

export const GET_CAREERS_LIST = 'GET_CAREERS_LIST';
export const GET_CAREERS_LIST_SUCCESS = 'GET_CAREERS_LIST_SUCCESS';
export const GET_CAREERS_LIST_FAILURE = 'GET_CAREERS_LIST_FAILURE';
export const RESET_CAREERS_LIST = 'RESET_CAREERS_LIST';

// ======================FAQ====================

export const GET_FAQ_LIST = 'GET_FAQ_LIST';
export const GET_FAQ_LIST_SUCCESS = 'GET_FAQ_LIST_SUCCESS';
export const GET_FAQ_LIST_FAILURE = 'GET_FAQ_LIST_FAILURE';
export const RESET_FAQ_LIST = 'RESET_FAQ_LIST';

// ====================Category & Indicators==================

export const GET_CATEGORY_INDICATORS_LIST = 'GET_CATEGORY_INDICATORS_LIST';
export const GET_CATEGORY_INDICATORS_LIST_SUCCESS = 'GET_CATEGORY_INDICATORS_LIST_SUCCESS';
export const GET_CATEGORY_INDICATORS_LIST_FAILURE = 'GET_CATEGORY_INDICATORS_LIST_FAILURE';

// ====================Countries==================

export const GET_COUNTRIES_LIST = 'GET_COUNTRIES_LIST';
export const GET_COUNTRIES_LIST_SUCCESS = 'GET_COUNTRIES_LIST_SUCCESS';
export const GET_COUNTRIES_LIST_FAILURE = 'GET_COUNTRIES_LIST_FAILURE';

// ====================My Profile==================

export const GET_USER_PROFILE = 'GET_USER_PROFILE';
export const GET_USER_PROFILE_SUCCESS = 'GET_USER_PROFILE_SUCCESS';
export const GET_USER_PROFILE_FAILURE = 'GET_USER_PROFILE_FAILURE';

export const EDIT_PROFILE = 'EDIT_PROFILE';
export const EDIT_PROFILE_SUCCESS = 'EDIT_PROFILE_SUCCESS';
export const EDIT_PROFILE_FAILURE = 'EDIT_PROFILE_FAILURE';

export const CHANGE_PASSWORD = 'CHANGE_PASSWORD';
export const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS';
export const CHANGE_PASSWORD_FAILURE = 'CHANGE_PASSWORD_FAILURE';

//====================Home==============================

export const GET_TRENDING_DATA = 'GET_TRENDING_DATA';
export const GET_TRENDING_DATA_SUCCESS = 'GET_TRENDING_DATA_SUCCESS';
export const GET_TRENDING_DATA_FAILURE = 'GET_TRENDING_DATA_FAILURE';

export const GET_AUTO_SEARCH_LIST = 'GET_AUTO_SEARCH_LIST';
export const GET_AUTO_SEARCH_LIST_SUCCESS = 'GET_AUTO_SEARCH_LIST_SUCCESS';
export const GET_AUTO_SEARCH_LIST_FAILURE = 'GET_AUTO_SEARCH_LIST_FAILURE';
export const RESET_AUTO_SEARCH = 'RESET_AUTO_SEARCH';

export const GET_QUICK_SEARCH_DATA = 'GET_QUICK_SEARCH_DATA';
export const GET_QUICK_SEARCH_DATA_SUCCESS = 'GET_QUICK_SEARCH_DATA_SUCCESS';
export const GET_QUICK_SEARCH_DATA_FAILURE = 'GET_QUICK_SEARCH_DATA_FAILURE';
export const RESTE_QUICK_SEARCH_DATA = 'RESTE_QUICK_SEARCH_DATA';

export const GET_RELATED_DATA = 'GET_RELATED_DATA';
export const GET_RELATED_DATA_SUCCESS = 'GET_RELATED_DATA_SUCCESS';
export const GET_RELATED_DATA_FAILURE = 'GET_RELATED_DATA_FAILURE';

export const GET_RANDOM_SUGGESTION_SEARCH_DATA = 'GET_RANDOM_SUGGESTION_SEARCH_DATA';
export const GET_RANDOM_SUGGESTION_SEARCH_DATA_SUCCESS = 'GET_RANDOM_SUGGESTION_SEARCH_DATA_SUCCESS';
export const GET_RANDOM_SUGGESTION_SEARCH_DATA_FAILURE = 'GET_RANDOM_SUGGESTION_SEARCH_DATA_FAILURE';
//======================Country Dashboard ==========================

export const GET_COUNTRY_DETAILS = 'GET_COUNTRY_DETAILS';
export const GET_COUNTRY_DETAILS_SUCCESS = 'GET_COUNTRY_DETAILS_SUCCESS';
export const GET_COUNTRY_DETAILS_FAILURE = 'GET_COUNTRY_DETAILS_FAILURE';

//==================== Country Indicator Search Data  =======================

export const GET_COUNTRY_INDICATOR_DETAILS = 'GET_COUNTRY_INDICATOR_DETAILS';
export const GET_COUNTRY_INDICATOR_DETAILS_SUCCESS = 'GET_COUNTRY_INDICATOR_DETAILS_SUCCESS';
export const GET_COUNTRY_INDICATOR_DETAILS_FAILURE = 'GET_COUNTRY_INDICATOR_DETAILS_FAILURE';
export const RESET_COUNTRY_INDICATOR_DETAILS = 'RESET_COUNTRY_INDICATOR_DETAILS';

export const GET_COUNTRY_VICE_INDICATOR_LIST = 'GET_COUNTRY_VICE_INDICATOR_LIST';
export const GET_COUNTRY_VICE_INDICATOR_LIST_SUCCESS = 'GET_COUNTRY_VICE_INDICATOR_LIST_SUCCESS';
export const GET_COUNTRY_VICE_INDICATOR_LIST_FAILURE = 'GET_COUNTRY_VICE_INDICATOR_LIST_FAILURE';

export const GET_COUNTRY_VICE_INDICATOR_LIST_SECOND = 'GET_COUNTRY_VICE_INDICATOR_LIST_SECOND';
export const GET_COUNTRY_VICE_INDICATOR_LIST_SECOND_SUCCESS = 'GET_COUNTRY_VICE_INDICATOR_LIST_SECOND_SUCCESS';
export const GET_COUNTRY_VICE_INDICATOR_LIST_SECOND_FAILURE = 'GET_COUNTRY_VICE_INDICATOR_LIST_SECOND_FAILURE';

export const GET_DATA_ANALYSIS_DATA = 'GET_DATA_ANALYSIS_DATA';
export const GET_DATA_ANALYSIS_DATA_SUCCESS = 'GET_DATA_ANALYSIS_DATA_SUCCESS';
export const GET_DATA_ANALYSIS_DATA_FAILURE = 'GET_DATA_ANALYSIS_DATA_FAILURE';

export const MARK_CHART_FAVOURITE = 'MARK_CHART_FAVOURITE';
export const MARK_CHART_FAVOURITE_SUCCESS = 'MARK_CHART_FAVOURITE_SUCCESS';
export const MARK_CHART_FAVOURITE_FAILURE = 'MARK_CHART_FAVOURITE_FAILURE';

export const GET_COUNTRY_DETAILS_GRAPH = 'GET_COUNTRY_DETAILS_GRAPH';
export const GET_COUNTRY_DETAILS_GRAPH_SUCCESS = 'GET_COUNTRY_DETAILS_GRAPH_SUCCESS';
export const GET_COUNTRY_DETAILS_GRAPH_FAILURE = 'GET_COUNTRY_DETAILS_GRAPH_FAILURE';
export const RESET_COUNTRY_DETAILS_GRAPH = 'RESET_COUNTRY_DETAILS_GRAPH';

export const GET_COUNTRY_INDICATOR_WISE = 'GET_COUNTRY_INDICATOR_WISE';
export const GET_COUNTRY_INDICATOR_WISE_SUCCESS = 'GET_COUNTRY_INDICATOR_WISE_SUCCESS';
export const GET_COUNTRY_INDICATOR_WISE_FAILURE = 'GET_COUNTRY_INDICATOR_WISE_FAILURE';

export const STORE_COUNTRY_DATA = 'STORE_COUNTRY_DATA';
export const RESET_STORE_COUNTRY_DATA = 'RESET_STORE_COUNTRY_DATA';

//================== Random Search ================================

export const GET_RANDOM_SEARCH_DATA = 'GET_RANDOM_SEARCH_DATA';
export const GET_RANDOM_SEARCH_DATA_SUCCESS = 'GET_RANDOM_SEARCH_DATA_SUCCESS';
export const GET_RANDOM_SEARCH_DATA_FAILURE = 'GET_RANDOM_SEARCH_DATA_FAILURE';
export const RESET_RANDOM_SEARCH = 'RESET_RANDOM_SEARCH';

export const GET_ADD_ON_DATA = 'GET_ADD_ON_DATA';
export const GET_ADD_ON_DATA_SUCCESS = 'GET_ADD_ON_DATA_SUCCESS';
export const GET_ADD_ON_DATA_FAILURE = 'GET_ADD_ON_DATA_FAILURE';
export const RESET_ADD_ON_DATA = 'RESET_ADD_ON_DATA';

//================== My Favourite Chart ================================
export const GET_MY_CHARTS_DATA = 'GET_MY_CHARTS_DATA';
export const GET_MY_CHARTS_DATA_SUCCESS = 'GET_MY_CHARTS_DATA_SUCCESS';
export const GET_MY_CHARTS_DATA_FAILURE = 'GET_MY_CHARTS_DATA_FAILURE';

//================= About Us ====================================
export const GET_ABOUT_US_DATA = 'GET_ABOUT_US_DATA';
export const GET_ABOUT_US_DATA_SUCCESS = 'GET_ABOUT_US_DATA_SUCCESS';
export const GET_ABOUT_US_DATA_FAILURE = 'GET_ABOUT_US_DATA_FAILURE';

//========================= CMS Pages=========================================

export const GET_PRIVACY_POLICY_DATA = 'GET_PRIVACY_POLICY_DATA';
export const GET_PRIVACY_POLICY_DATA_SUCCESS = 'GET_PRIVACY_POLICY_DATA_SUCCESS';
export const GET_PRIVACY_POLICY_DATA_FAILURE = 'GET_PRIVACY_POLICY_DATA_FAILURE';

export const GET_TERMS_CONDITION_DATA = 'GET_TERMS_CONDITION_DATA';
export const GET_TERMS_CONDITION_DATA_SUCCESS = 'GET_TERMS_CONDITION_DATA_SUCCESS';
export const GET_TERMS_CONDITION_DATA_FAILURE = 'GET_TERMS_CONDITION_DATA_FAILURE';

//============================== Notification ===============================

export const GET_NOTIFICATION_DATA = 'GET_NOTIFICATION_DATA';
export const GET_NOTIFICATION_DATA_SUCCESS = 'GET_NOTIFICATION_DATA_SUCCESS';
export const GET_NOTIFICATION_DATA_FAILURE = 'GET_NOTIFICATION_DATA_FAILURE';
