import {
    GET_MY_CHARTS_DATA,
    GET_MY_CHARTS_DATA_SUCCESS,
    GET_MY_CHARTS_DATA_FAILURE,
} from './types';

export const getMyChartsData = () => ({
    type: GET_MY_CHARTS_DATA
});

export const getMyChartsDataSuccess = (payload) => ({
    type: GET_MY_CHARTS_DATA_SUCCESS,
    payload
});

export const getMyChartsDataFailure = (payload) => ({
    type: GET_MY_CHARTS_DATA_FAILURE,
    payload
});
