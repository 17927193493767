import { all, takeEvery, put } from 'redux-saga/effects';

import {GET_ABOUT_US_DATA} from '../action/types';
import { getAboutUsDataSuccess, getAboutUsDataFailure } from '../action';
import API from '../../utils/api';

function* getAboutUsDataRequest() {
    try {
        const res = yield API.get('cms/about-us');
        if(res.data.meta.code === 1){
            yield put(getAboutUsDataSuccess(res.data.data));
        }else if(res.data.meta.code === 0){
            yield put(getAboutUsDataFailure(res.data.data));
        }
    }catch (e) {
        yield put(getAboutUsDataFailure(e));
    }
}

export function* watchGetAboutUsDataAPI() {
    yield takeEvery(GET_ABOUT_US_DATA, getAboutUsDataRequest);
}

export default function* rootSaga() {
    yield all([
        watchGetAboutUsDataAPI()
    ]);
}