import { all, takeEvery, put, call } from 'redux-saga/effects';
import {toast} from "react-toastify";

import { LOGIN_USER } from '../action/types';
import { loginUserSuccess, loginUserFailure } from '../action';
import API from '../../utils/api';
import { setToken } from '../../utils/helper';

function* loginUserRequest(action) {
    try {
        const res = yield API.post('login', {
            email: action.payload.email,
            password: action.payload.password
        });
        if(res.data.meta.code === 1){
            yield put(loginUserSuccess());
            yield call(setToken, res.data.meta.token);
            yield call(action.payload.callback)
            toast(res.data.meta.message);
        } else if(res.data.meta.code === 0) {
            yield put(loginUserFailure());
            toast(res.data.meta.message);
        }
    }catch (e) {
        yield put(loginUserFailure());
    }
}

export function* watchLoginUserAPI() {
    yield takeEvery(LOGIN_USER, loginUserRequest);
}

export default function* rootSaga() {
    yield all([
        watchLoginUserAPI()
    ]);
}