import {
    SUPPORT_FORM,
    SUPPORT_FORM_SUCCESS,
    SUPPORT_FORM_FAILURE
} from '../action/types';

const INIT_STATE = {
    loading: false
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case SUPPORT_FORM:
            return { ...state, loading: true };
        case SUPPORT_FORM_SUCCESS:
            return { ...state, loading: false };
        case SUPPORT_FORM_FAILURE:
            return { ...state, loading: false };
        default:
            return state;
    }
};