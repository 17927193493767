import {
    GET_TERMS_CONDITION_DATA,
    GET_TERMS_CONDITION_DATA_SUCCESS,
    GET_TERMS_CONDITION_DATA_FAILURE,

    GET_PRIVACY_POLICY_DATA,
    GET_PRIVACY_POLICY_DATA_SUCCESS,
    GET_PRIVACY_POLICY_DATA_FAILURE
} from './types';

export const getPrivacyPolicyData = () => ({
    type: GET_PRIVACY_POLICY_DATA
});

export const getPrivacyPolicyDataSuccess = (payload) => ({
    type: GET_PRIVACY_POLICY_DATA_SUCCESS,
    payload
});

export const getPrivacyPolicyDataFailure = (payload) => ({
    type: GET_PRIVACY_POLICY_DATA_FAILURE,
    payload
});

export const getTermConditionData = () => ({
    type: GET_TERMS_CONDITION_DATA
});

export const getTermConditionDataSuccess = (payload) => ({
    type: GET_TERMS_CONDITION_DATA_SUCCESS,
    payload
});

export const getTermConditionDataFailure = (payload) => ({
    type: GET_TERMS_CONDITION_DATA_FAILURE,
    payload
});