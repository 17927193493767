import {all, takeEvery, put, call} from 'redux-saga/effects';
import {toast} from 'react-toastify';

import {CHANGE_PASSWORD, EDIT_PROFILE, GET_USER_PROFILE} from '../action/types';
import {
    getUserProfileSuccess,
    getUserProfileFailure,
    editProfileSuccess,
    editProfileFailure,
    changePasswordSuccess, changePasswordFailure
} from '../action';
import API from '../../utils/api';
import {setPasswordInStorage} from "../../utils/helper";

function* getUserProfileRequest() {
    try {
        const res = yield API.get('my-profile');
        if(res.data.meta.code === 1){
            yield put(getUserProfileSuccess(res.data.data));
        } else if(res.data.meta.code === 0) {
            yield put(getUserProfileFailure(res.data.data));
        }
    }catch (e) {
        yield put(getUserProfileFailure(e));
    }
}

function* editProfileRequest(action) {
    const { email, name, country_id, image, callback } = action.payload;
    const bodyFormData = new FormData();
    bodyFormData.append('email', email)
    bodyFormData.append('name', name)
    bodyFormData.append('country_id', country_id)
    image && bodyFormData.append('image', image)
    try {
        const res = yield API.post('update-profile', bodyFormData);
        if(res.data.meta.code === 1){
            yield put(editProfileSuccess());
            yield call(getUserProfileRequest);
            yield call(callback);
            toast(res.data.meta.message);
        } else if(res.data.meta.code === 0) {
            yield put(editProfileFailure());
            toast(res.data.meta.message);
        }
    }catch (e) {
        yield put(editProfileFailure());
    }
}

function* changePasswordRequest(action) {
    const {old_password, password, callback} = action.payload;
    try {
        const res = yield API.post('change-password', {old_password, password});
        if(res.data.meta.code === 1) {
            yield put(changePasswordSuccess());
            yield call(callback);
            yield call(setPasswordInStorage, password);
            toast(res.data.meta.message);
        } else if(res.data.meta.code === 0) {
            yield put(changePasswordFailure());
            toast(res.data.meta.message);
        }
    } catch (e) {
        yield put(changePasswordFailure());
    }
}

export function* watchGetUserProfileAPI() {
    yield takeEvery(GET_USER_PROFILE, getUserProfileRequest);
}

export function* watchEditProfileAPI() {
    yield takeEvery(EDIT_PROFILE, editProfileRequest);
}

export function* watchChangePasswordAPI() {
    yield takeEvery(CHANGE_PASSWORD, changePasswordRequest)
}

export default function* rootSaga() {
    yield all([
        watchGetUserProfileAPI(),
        watchEditProfileAPI(),
        watchChangePasswordAPI()
    ]);
}