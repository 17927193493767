import { all, takeEvery, put } from 'redux-saga/effects';

import {SUPPORT_FORM} from '../action/types';
import { supportFormSuccess, supportFormFailure } from '../action';
import API from '../../utils/api';
import {toast} from "react-toastify";

function* supportFormRequest(actions) {
    try {
        const res = yield API.post('support',actions.payload);
        if(res.data.meta.code === 1){
            yield put(supportFormSuccess());
            yield toast('Your support message is successfully sent. Soon We will reach out to your queries.');
        }
    }catch (e) {
        yield put(supportFormFailure());
    }
}

export function* watchSupportFormAPI() {
    yield takeEvery(SUPPORT_FORM, supportFormRequest);
}

export default function* rootSaga() {
    yield all([
        watchSupportFormAPI()
    ]);
}