import {
    REGISTER_USER,
    REGISTER_USER_SUCCESS,
    REGISTER_USER_FAILURE
} from './types';

export const registerUser = payload => ({
    type: REGISTER_USER,
    payload
});

export const registerUserSuccess = () => ({
    type: REGISTER_USER_SUCCESS
});

export const registerUserFailure = () => ({
    type: REGISTER_USER_FAILURE
});