import {
    GET_CATEGORY_INDICATORS_LIST,
    GET_CATEGORY_INDICATORS_LIST_SUCCESS,
    GET_CATEGORY_INDICATORS_LIST_FAILURE
} from './types';

export const getCategoryAndIndicatorsList = () => ({
    type: GET_CATEGORY_INDICATORS_LIST
});

export const getCategoryAndIndicatorsListSuccess = (payload) => ({
    type: GET_CATEGORY_INDICATORS_LIST_SUCCESS,
    payload
});

export const getCategoryAndIndicatorsListFailure = (payload) => ({
    type: GET_CATEGORY_INDICATORS_LIST_FAILURE,
    payload
});
