import {
    GET_COUNTRY_DETAILS,
    GET_COUNTRY_DETAILS_SUCCESS,
    GET_COUNTRY_DETAILS_FAILURE
} from './types'

export const getCountryDetails = (payload) => ({
    type: GET_COUNTRY_DETAILS,
    payload
});

export const getCountryDetailsSuccess = (payload) => ({
    type: GET_COUNTRY_DETAILS_SUCCESS,
    payload
});

export const getCountryDetailsFailure = (payload) => ({
    type: GET_COUNTRY_DETAILS_FAILURE,
    payload
});