import {
    GET_TRENDING_DATA,
    GET_TRENDING_DATA_SUCCESS,
    GET_TRENDING_DATA_FAILURE,

    GET_AUTO_SEARCH_LIST,
    GET_AUTO_SEARCH_LIST_SUCCESS,
    GET_AUTO_SEARCH_LIST_FAILURE,
    RESET_AUTO_SEARCH,

    GET_QUICK_SEARCH_DATA,
    GET_QUICK_SEARCH_DATA_SUCCESS,
    GET_QUICK_SEARCH_DATA_FAILURE,
    RESTE_QUICK_SEARCH_DATA,

    GET_NOTIFICATION_DATA,
    GET_NOTIFICATION_DATA_SUCCESS,
    GET_NOTIFICATION_DATA_FAILURE,

    GET_RELATED_DATA,
    GET_RELATED_DATA_SUCCESS,
    GET_RELATED_DATA_FAILURE,

    GET_RANDOM_SUGGESTION_SEARCH_DATA,
    GET_RANDOM_SUGGESTION_SEARCH_DATA_SUCCESS,
    GET_RANDOM_SUGGESTION_SEARCH_DATA_FAILURE
} from './types'

export const getTrendingData = () => ({
    type: GET_TRENDING_DATA
});

export const getTrendingDataSuccess = (payload) => ({
    type: GET_TRENDING_DATA_SUCCESS,
    payload
});

export const getTrendingDataFailure = (payload) => ({
    type: GET_TRENDING_DATA_FAILURE,
    payload
});

export const getAutoSearchList = payload => ({
    type: GET_AUTO_SEARCH_LIST,
    payload
});

export const getAutoSearchListSuccess = payload => ({
   type: GET_AUTO_SEARCH_LIST_SUCCESS,
   payload
});

export const getAutoSearchListFailure = payload => ({
    type: GET_AUTO_SEARCH_LIST_FAILURE,
    payload
});

export const resetAutoSearch = () => ({
    type: RESET_AUTO_SEARCH
});

export const getQuickSearchData = payload => ({
    type: GET_QUICK_SEARCH_DATA,
    payload
});

export const getQuickSearchDataSuccess = payload => ({
    type: GET_QUICK_SEARCH_DATA_SUCCESS,
    payload
});

export const getQuickSearchDataFailure = payload => ({
    type: GET_QUICK_SEARCH_DATA_FAILURE,
    payload
});

export const resetQuickSearchData = () => ({
    type: RESTE_QUICK_SEARCH_DATA
});

export const getNotificationData = (payload) => ({
   type: GET_NOTIFICATION_DATA,
    payload
});

export const getNotificationDataSuccess = (payload) => ({
    type: GET_NOTIFICATION_DATA_SUCCESS,
    payload
});

export const getNotificationDataFailure = (payload) => ({
    type: GET_NOTIFICATION_DATA_FAILURE,
    payload
});

export const getRelatedSearch = payload => ({
    type: GET_RELATED_DATA,
    payload
});

export const getRelatedSearchSuccess = payload => ({
   type: GET_RELATED_DATA_SUCCESS,
   payload
});

export const getRelatedSearchFailure = payload => ({
    type: GET_RELATED_DATA_FAILURE,
    payload
})

export const getRandomSuggestionSearchData = payload => ({
    type: GET_RANDOM_SUGGESTION_SEARCH_DATA,
    payload
});

export const getRandomSuggestionSearchDataSuccess = payload => ({
    type: GET_RANDOM_SUGGESTION_SEARCH_DATA_SUCCESS,
    payload
});

export const getRandomSuggestionSearchDataFailure = payload => ({
    type: GET_RANDOM_SUGGESTION_SEARCH_DATA_FAILURE,
    payload
});

export const resetRandomSearch = () => ({
    type: RESET_AUTO_SEARCH
});