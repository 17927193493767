import { all, takeEvery, put } from 'redux-saga/effects';
import axios from 'axios';

import {
    GET_AUTO_SEARCH_LIST,
    GET_TRENDING_DATA,
    GET_QUICK_SEARCH_DATA,
    GET_NOTIFICATION_DATA,
    GET_RELATED_DATA,
    GET_RANDOM_SUGGESTION_SEARCH_DATA
} from '../action/types';
import {
    getTrendingDataSuccess,
    getTrendingDataFailure,
    getAutoSearchListSuccess,
    getAutoSearchListFailure,
    getQuickSearchDataSuccess,
    getQuickSearchDataFailure,
    getNotificationDataSuccess,
    getNotificationDataFailure,
    getRelatedSearchSuccess,
    getRelatedSearchFailure,
    getRandomSuggestionSearchDataSuccess,
    getRandomSuggestionSearchDataFailure
} from '../action';
import API from '../../utils/api';
import {getToken} from "../../utils/helper";

const CancelToken = axios.CancelToken;
let cancel;

function* trendingDataRequest() {
    try {
        const res = yield API.get('trending-data');
        if(res.data.meta.code === 1) {
            yield put(getTrendingDataSuccess(res.data.data));
        }
    } catch (e) {
        yield put(getTrendingDataFailure(e));
    }
}

function* getAutoSearchListRequest(action) {
    try {
        cancel && cancel();
        const res = yield API.post('auto-suggestion', action.payload, {
            cancelToken: new CancelToken(function executor(c) {
                cancel = c;
            })
        })
        if(res.data.meta.code === 1 && res.data.meta.message === 'success') {
            yield put(getAutoSearchListSuccess(res.data.data));
        } else if(res.data.meta.message === 'No data found') {
            yield put(getAutoSearchListSuccess('No data found'));
        }
    } catch (e) {
        yield put(getAutoSearchListFailure(e));
    }
}

function* getQuickSearchDataRequest(action) {
    try {
        const url = getToken() ? 'country-indicator-search' : 'compare-search';
        const res = yield API.post(url, action.payload);
        if(res.data.meta.code === 1){
            yield put(getQuickSearchDataSuccess(res.data.data));
        }
    }catch (e) {
        yield put(getQuickSearchDataFailure(e));
    }
}

function* getNotificationDataRequest(action) {
    const { page, per_page } = action.payload;
    const url = `notifications?page=${page}&per_page=${per_page}`;
    try {
        const res = yield API.get(url);
        if(res.data.meta.code === 1){
            yield put(getNotificationDataSuccess(res.data));
        }
    }catch (e) {
        yield put(getNotificationDataFailure(e));
    }
}

function* getRelatedDataRequest(action) {
    try{
        const res = yield API.post('related-search', {
            search: action.payload
        })
        if(res.data.meta.code === 1) {
            yield put(getRelatedSearchSuccess(res.data.data))
        } else {
            yield put(getRelatedSearchFailure(res))
        }
    } catch (e) {
        yield put(getRelatedSearchFailure(e))
    }
}

function* getRandomSuggestionSearchDataRequest(action) {
    try {
        const res = yield API.post('auto-suggestion', action.payload)
        if(res.data.meta.code === 1) {
            yield put(getRandomSuggestionSearchDataSuccess(res.data.data));
        } else {
            yield put(getRandomSuggestionSearchDataFailure(res));
        }
    } catch (e) {
        yield put(getRandomSuggestionSearchDataFailure(e));
    }
}

export function* watchGetTrendingDataAPI() {
    yield takeEvery(GET_TRENDING_DATA, trendingDataRequest);
}

export function* watchGetAutoSearchListAPI() {
    yield takeEvery(GET_AUTO_SEARCH_LIST, getAutoSearchListRequest);
}

export function* watchGetQuickSearchDataAPI() {
    yield takeEvery(GET_QUICK_SEARCH_DATA, getQuickSearchDataRequest);
}

export function* watchGetNotificationDataAPI() {
    yield takeEvery(GET_NOTIFICATION_DATA, getNotificationDataRequest);
}

export function* watchGetRelatedData() {
    yield takeEvery(GET_RELATED_DATA, getRelatedDataRequest)
}

export function* watchRandomSuggestionData() {
    yield takeEvery(GET_RANDOM_SUGGESTION_SEARCH_DATA, getRandomSuggestionSearchDataRequest)
}

export default function* rootSaga() {
    yield all([
        watchGetTrendingDataAPI(),
        watchGetAutoSearchListAPI(),
        watchGetQuickSearchDataAPI(),
        watchGetNotificationDataAPI(),
        watchGetRelatedData(),
        watchRandomSuggestionData()
    ]);
}