import {
    GET_COUNTRIES_LIST,
    GET_COUNTRIES_LIST_SUCCESS,
    GET_COUNTRIES_LIST_FAILURE
} from './types'

export const getCountriesList = (payload) => ({
    type: GET_COUNTRIES_LIST,
    payload
});

export const getCountriesListSuccess = (payload) => ({
    type: GET_COUNTRIES_LIST_SUCCESS,
    payload
});

export const getCountriesListFailure = (payload) => ({
    type: GET_COUNTRIES_LIST_FAILURE,
    payload
});