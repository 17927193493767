import {
    GET_CAREERS_LIST,
    GET_CAREERS_LIST_SUCCESS,
    GET_CAREERS_LIST_FAILURE,
    RESET_CAREERS_LIST
} from '../action/types';

const INIT_STATE = {
    loading: false,
    data: [],
    pagination: {},
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_CAREERS_LIST:
            return { ...state, loading: true };
        case GET_CAREERS_LIST_SUCCESS:
            return { ...state, loading: false, data: state.data.concat(action.payload.data), pagination: action.payload.meta };
        case GET_CAREERS_LIST_FAILURE:
            return { ...state, loading: false, data: action.payload };
        case RESET_CAREERS_LIST:
            return { ...state, data: [] };
        default:
            return state;
    }
};