import {
    GET_COUNTRIES_LIST,
    GET_COUNTRIES_LIST_SUCCESS,
    GET_COUNTRIES_LIST_FAILURE
} from '../action/types';

const INIT_STATE = {
    loading: false,
    data:[]
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_COUNTRIES_LIST:
            return {...state, loading: true};
        case GET_COUNTRIES_LIST_SUCCESS:
            return {...state, loading: false, data: action.payload};
        case GET_COUNTRIES_LIST_FAILURE:
            return {...state, loading: false, data: action.payload};
        default:
            return state;
    }
};