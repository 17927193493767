import { all, takeEvery, put } from 'redux-saga/effects';

import {GET_CAREERS_LIST} from '../action/types';
import { getCareersListSuccess, getCareersListFailure } from '../action';
import API from '../../utils/api';

function* careersListRequest(action) {
    const { page, per_page } = action.payload;
    const url = `careers?page=${page}&per_page=${per_page}`;
    try {
        const res = yield API.get(url);
        if(res.data.meta.code === 1){
            yield put(getCareersListSuccess(res.data));
        }
    }catch (e) {
        yield put(getCareersListFailure(e));
    }
}

export function* watchCareersLisAPI() {
    yield takeEvery(GET_CAREERS_LIST, careersListRequest);
}

export default function* rootSaga() {
    yield all([
        watchCareersLisAPI()
    ]);
}