import React, { Suspense, lazy, memo } from 'react';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';

import { Loader } from '../component/CommonComponent';
import ErrorBoundary from '../component/ErrorBoundary';
import RoutesFile from './RoutesFile';
import PrivateRoute from "./PrivateRoute";

const Header = lazy(() => import('../container/Header'));
const Footer = lazy(() => import('../container/footer'));

const Routes = memo(() => {
    return (
        <Router>
            <ErrorBoundary>
                <Suspense fallback={<Loader/>}>
                    <Header/>
                </Suspense>
                <Suspense fallback={<Loader/>}>
                    <Switch>
                        {
                            RoutesFile.map((itm, key) =>
                                itm.private ?
                                    <PrivateRoute
                                        key={key}
                                        exact={itm.exact}
                                        path={itm.path}
                                        name={itm.name}
                                        component={itm.component}
                                    />:
                                    <Route
                                        key={key}
                                        exact={itm.exact}
                                        path={itm.path}
                                        name={itm.name}
                                        component={itm.component}
                                    />
                            )
                        }
                    </Switch>
                </Suspense>
                <Suspense fallback={<Loader/>}>
                    <Footer/>
                </Suspense>
            </ErrorBoundary>
        </Router>
    );
});

export default Routes;