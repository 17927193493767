import {
    GET_USER_PROFILE,
    GET_USER_PROFILE_SUCCESS,
    GET_USER_PROFILE_FAILURE,

    EDIT_PROFILE,
    EDIT_PROFILE_SUCCESS,
    EDIT_PROFILE_FAILURE,

    CHANGE_PASSWORD,
    CHANGE_PASSWORD_SUCCESS,
    CHANGE_PASSWORD_FAILURE
} from './types';

export const getUserProfile = () => ({
    type: GET_USER_PROFILE
});

export const getUserProfileSuccess = payload => ({
    type: GET_USER_PROFILE_SUCCESS,
    payload
});

export const getUserProfileFailure = payload => ({
    type: GET_USER_PROFILE_FAILURE,
    payload
});

export const editProfile = (payload) => ({
    type: EDIT_PROFILE,
    payload
});

export const editProfileSuccess = () => ({
   type: EDIT_PROFILE_SUCCESS
});

export const editProfileFailure = () => ({
    type: EDIT_PROFILE_FAILURE
});

export const changePassword = (payload) => ({
    type: CHANGE_PASSWORD,
    payload
});

export const changePasswordSuccess = () => ({
    type: CHANGE_PASSWORD_SUCCESS
});

export const changePasswordFailure = () => ({
    type: CHANGE_PASSWORD_FAILURE
});