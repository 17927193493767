import React from 'react';
import PropTypes from 'prop-types';

const SelectWithLabel = ({name, label, required, errorMsg, className, value, onChange, placeholder, options}) => {
    return (
        <div className='select-with-label'>
            <label htmlFor={name} className='f-14'>
                {label}
                {required && <span className='text-danger'>*</span>}
            </label>
            <select
                name={name}
                id={name}
                className={value === '' ? `${className} f-italic-12` : className}
                value={value}
                onChange={onChange}
            >
                <option value="" disabled className='f-italic-12'>{placeholder}</option>
                {
                    options.map(itm =>
                        <option value={itm.value} key={itm.value}>{itm.label}</option>
                    )
                }
            </select>
            <div className="invalid-feedback">
                {errorMsg}
            </div>
        </div>
    );
};

SelectWithLabel.propTypes = {
    name: PropTypes.string,
    label: PropTypes.string,
    required: PropTypes.bool,
    errorMsg: PropTypes.string,
    className: PropTypes.string,
    value: PropTypes.string,
    onChange: PropTypes.func,
    placeholder: PropTypes.string,
    options: PropTypes.array
};

export default SelectWithLabel;