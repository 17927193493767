import {
    GET_COUNTRY_INDICATOR_DETAILS,
    GET_COUNTRY_INDICATOR_DETAILS_SUCCESS,
    GET_COUNTRY_INDICATOR_DETAILS_FAILURE,
    RESET_COUNTRY_INDICATOR_DETAILS,

    GET_COUNTRY_VICE_INDICATOR_LIST,
    GET_COUNTRY_VICE_INDICATOR_LIST_SUCCESS,
    GET_COUNTRY_VICE_INDICATOR_LIST_FAILURE,

    GET_COUNTRY_VICE_INDICATOR_LIST_SECOND,
    GET_COUNTRY_VICE_INDICATOR_LIST_SECOND_SUCCESS,
    GET_COUNTRY_VICE_INDICATOR_LIST_SECOND_FAILURE,

    GET_DATA_ANALYSIS_DATA,
    GET_DATA_ANALYSIS_DATA_SUCCESS,
    GET_DATA_ANALYSIS_DATA_FAILURE,

    MARK_CHART_FAVOURITE,
    MARK_CHART_FAVOURITE_SUCCESS,
    MARK_CHART_FAVOURITE_FAILURE,

    GET_COUNTRY_DETAILS_GRAPH,
    GET_COUNTRY_DETAILS_GRAPH_SUCCESS,
    GET_COUNTRY_DETAILS_GRAPH_FAILURE,
    RESET_COUNTRY_DETAILS_GRAPH,

    GET_COUNTRY_INDICATOR_WISE,
    GET_COUNTRY_INDICATOR_WISE_SUCCESS,
    GET_COUNTRY_INDICATOR_WISE_FAILURE,

    STORE_COUNTRY_DATA, RESET_STORE_COUNTRY_DATA
} from '../action/types';

const INIT_STATE = {
    loading: false,
    chartLoad: false,
    data: {},
    indicatorList : [],
    dataAnalysis: '',
    detailsGraph: {},
    countryIndicatorWise: [],
    indicatorListSecond: [],
    dataForAnalysis: []
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_COUNTRY_INDICATOR_DETAILS:
            return {...state, chartLoad: true};
        case GET_COUNTRY_INDICATOR_DETAILS_SUCCESS:
            return {...state, chartLoad: false, data: action.payload};
        case GET_COUNTRY_INDICATOR_DETAILS_FAILURE:
            return {...state, chartLoad: false, data: action.payload};
        case RESET_COUNTRY_INDICATOR_DETAILS:
            return { ...state, data: {} };
        case GET_COUNTRY_VICE_INDICATOR_LIST:
            return {...state, loading: true};
        case GET_COUNTRY_VICE_INDICATOR_LIST_SUCCESS:
            return {...state, loading: false, indicatorList: action.payload};
        case GET_COUNTRY_VICE_INDICATOR_LIST_FAILURE:
            return {...state, loading: false, indicatorList: action.payload};
        case GET_COUNTRY_VICE_INDICATOR_LIST_SECOND:
            return {...state, loading: true};
        case GET_COUNTRY_VICE_INDICATOR_LIST_SECOND_SUCCESS:
            return {...state, loading: false, indicatorListSecond: action.payload};
        case GET_COUNTRY_VICE_INDICATOR_LIST_SECOND_FAILURE:
            return {...state, loading: false, indicatorListSecond: action.payload};
        case GET_DATA_ANALYSIS_DATA:
            return {...state, loading: true};
        case GET_DATA_ANALYSIS_DATA_SUCCESS:
            return {...state, loading: false, dataAnalysis: action.payload};
        case GET_DATA_ANALYSIS_DATA_FAILURE:
            return {...state, loading: false, dataAnalysis: action.payload};
        case MARK_CHART_FAVOURITE:
            return {...state, loading: true};
        case MARK_CHART_FAVOURITE_SUCCESS:
            return {...state, loading: false};
        case MARK_CHART_FAVOURITE_FAILURE:
            return {...state, loading: false};
        case GET_COUNTRY_DETAILS_GRAPH:
            return { ...state, loading: true };
        case GET_COUNTRY_DETAILS_GRAPH_SUCCESS:
            return { ...state, loading: false, detailsGraph: action.payload };
        case GET_COUNTRY_DETAILS_GRAPH_FAILURE:
            return { ...state, loading: false, detailsGraph: action.payload };
        case RESET_COUNTRY_DETAILS_GRAPH:
            return { ...state, detailsGraph: {} };
        case GET_COUNTRY_INDICATOR_WISE:
            return { ...state, loading: true };
        case GET_COUNTRY_INDICATOR_WISE_SUCCESS:
            return { ...state, loading: false, countryIndicatorWise: action.payload };
        case GET_COUNTRY_INDICATOR_WISE_FAILURE:
            return { ...state, loading: false, countryIndicatorWise: action.payload };
        case STORE_COUNTRY_DATA:
            return { ...state, dataForAnalysis: [...state.dataForAnalysis, action.payload] };
        case RESET_STORE_COUNTRY_DATA:
            return { ...state, dataForAnalysis: [] };
        default:
            return state;
    }
};