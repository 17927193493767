import {
    SUPPORT_FORM,
    SUPPORT_FORM_SUCCESS,
    SUPPORT_FORM_FAILURE
} from './types';

export const supportForm = payload => ({
    type: SUPPORT_FORM,
    payload
});

export const supportFormSuccess = () => ({
    type: SUPPORT_FORM_SUCCESS
});

export const supportFormFailure = () => ({
    type: SUPPORT_FORM_FAILURE
});
