import {
    GET_FAQ_LIST,
    GET_FAQ_LIST_SUCCESS,
    GET_FAQ_LIST_FAILURE,
    RESET_FAQ_LIST
} from './types'

export const getFAQList = (payload) => ({
    type: GET_FAQ_LIST,
    payload
});

export const getFAQListSuccess = (payload) => ({
    type: GET_FAQ_LIST_SUCCESS,
    payload
});

export const getFAQListFailure = (payload) => ({
    type: GET_FAQ_LIST_FAILURE,
    payload
});

export const resetFaqList = () => ({
   type: RESET_FAQ_LIST
});