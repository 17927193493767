import { all, takeEvery, put } from 'redux-saga/effects';

import {GET_CATEGORY_INDICATORS_LIST} from '../action/types';
import { getCategoryAndIndicatorsListSuccess, getCategoryAndIndicatorsListFailure } from '../action';
import API from '../../utils/api';

function* categoryAndIndicatorsListRequest() {
    try {
        const res = yield API.get('categories');
        if(res.data.meta.code === 1){
            yield put(getCategoryAndIndicatorsListSuccess(res.data.data));
        }
    }catch (e) {
        yield put(getCategoryAndIndicatorsListFailure(e));
    }
}

export function* watchCategoryAndIndicatorsListAPI() {
    yield takeEvery(GET_CATEGORY_INDICATORS_LIST, categoryAndIndicatorsListRequest);
}

export default function* rootSaga() {
    yield all([
        watchCategoryAndIndicatorsListAPI()
    ]);
}