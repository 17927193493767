import {
    GET_ABOUT_US_DATA,
    GET_ABOUT_US_DATA_SUCCESS,
    GET_ABOUT_US_DATA_FAILURE
} from './types';

export const getAboutUsData = () => ({
    type: GET_ABOUT_US_DATA
});

export const getAboutUsDataSuccess = (payload) => ({
    type: GET_ABOUT_US_DATA_SUCCESS,
    payload
});

export const getAboutUsDataFailure = (payload) => ({
    type: GET_ABOUT_US_DATA_FAILURE,
    payload
});
