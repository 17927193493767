import {
    GET_COUNTRY_DETAILS,
    GET_COUNTRY_DETAILS_SUCCESS,
    GET_COUNTRY_DETAILS_FAILURE
} from '../action/types';

const INIT_STATE = {
    loading: false,
    data: {}
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_COUNTRY_DETAILS:
            return {...state, loading: true};
        case GET_COUNTRY_DETAILS_SUCCESS:
            return {...state, loading: false, data: action.payload};
        case GET_COUNTRY_DETAILS_FAILURE:
            return {...state, loading: false, data: action.payload};
        default:
            return state;
    }
};