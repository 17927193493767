import { lazy } from 'react';

const Login = lazy(() => import('../container/login'));
const Register = lazy(() => import('../container/register'));
const Faq = lazy(() => import('../container/faq'));
const AboutUs = lazy(() => import('../container/aboutUs'));
const Careers = lazy(() => import('../container/careers'));
const Support = lazy(() => import('../container/support'));
const ForgotPassword = lazy(() => import('../container/forgotPassword'));
const ResetPassword = lazy(() => import('../container/resetPassword'));
const Home = lazy(() => import('../container/home'));
const CountryDashboard = lazy(() => import('../container/countryDashboard'));
const Profile = lazy(() => import('../container/myProfile.js'));
const MyCharts = lazy(() => import('../container/myCharts.js'));
const RandomSearch = lazy(() => import('../container/randomSearch.js'));
const CategoryAndIndicators = lazy(() => import('../container/categoryAndIndicators.js'));
const Countries = lazy(() => import('../container/countries'));
const SearchedData = lazy(() => import('../container/searchedData'));
const VerifyEmail = lazy(() => import('../container/verifyEmail'));
const PrivacyPolicy = lazy(() => import('../container/privacyPolicy'));
const TermsCondition = lazy(() => import('../container/termsCondition'));
const SearchRandom = lazy(() => import('../container/search-random'));

const routes = [
    {
        path: '/login',
        exact: true,
        name: 'Login',
        component: Login,
        private: false
    },
    {
        path: '/register',
        exact: true,
        name: 'Register',
        component: Register,
        private: false
    },
    {
        path: '/faq',
        exact: true,
        name: 'Faq',
        component: Faq,
        private: false
    },
    {
        path: '/about-us',
        exact: true,
        name: 'About Us',
        component: AboutUs,
        private: false
    },
    {
        path: '/careers',
        exact: true,
        name: 'Careers',
        component: Careers,
        private: false
    },
    {
        path: '/support',
        exact: true,
        name: 'Support',
        component: Support,
        private: false
    },
    {
        path: '/forgot-password',
        exact: true,
        name: 'Forgot Password',
        component: ForgotPassword,
        private: false
    },
    {
        path: '/reset-password',
        exact: true,
        name: 'Reset Password',
        component: ResetPassword,
        private: false
    },
    {
        path: '/',
        exact: true,
        name: 'Home',
        component: Home,
        private: true
    },
    {
        path: '/country',
        exact: true,
        name: 'Country Dashboard',
        component: CountryDashboard,
        private: true
    },
    {
        path: '/profile',
        exact: true,
        name: 'My Profile',
        component: Profile,
        private: true
    },
    {
        path: '/my-charts',
        exact: true,
        name: 'My Charts',
        component: MyCharts,
        private: true
    },
    {
        path: '/random-search',
        exact: true,
        name: 'Random Search',
        component: RandomSearch,
        private: true
    },
    {
        path: '/category-indicator',
        exact: true,
        name: 'Category and Indicators',
        component: CategoryAndIndicators,
        private: true
    },
    {
        path: '/countries',
        exact: true,
        name: 'countries',
        component: Countries,
        private: true
    },
    {
        path: '/web/verify',
        exact: true,
        name: 'Email Verification',
        component: VerifyEmail,
        private: false
    },
    {
        path: '/:country_slug/:indicator_slug',
        exact: true,
        name: 'Searched Data',
        component: SearchedData,
        private: true
    },
    {
        path: '/privacy-policy',
        exact: true,
        name: 'Privacy Policy',
        component: PrivacyPolicy,
        private: true
    },
    {
        path: '/terms-and-condition',
        exact: true,
        name: 'Terms & Condition',
        component: TermsCondition,
        private: true
    },
    {
        path: '/search-random',
        exact: true,
        name: 'Search Random Value',
        component: SearchRandom,
        private: true
    }
];

export default routes;
