import {
    GET_PRIVACY_POLICY_DATA,
    GET_PRIVACY_POLICY_DATA_SUCCESS,
    GET_PRIVACY_POLICY_DATA_FAILURE,

    GET_TERMS_CONDITION_DATA,
    GET_TERMS_CONDITION_DATA_SUCCESS,
    GET_TERMS_CONDITION_DATA_FAILURE
} from '../action/types';

const INIT_STATE = {
    loading: false,
    privacyPolicyData: {},
    termsConditionData: {}
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_PRIVACY_POLICY_DATA:
            return { ...state, loading: true };
        case GET_PRIVACY_POLICY_DATA_SUCCESS:
            return { ...state, loading: false, privacyPolicyData: action.payload };
        case GET_PRIVACY_POLICY_DATA_FAILURE:
            return { ...state, loading: false, privacyPolicyData: action.payload };
        case GET_TERMS_CONDITION_DATA:
            return { ...state, loading: true };
        case GET_TERMS_CONDITION_DATA_SUCCESS:
            return { ...state, loading: false, termsConditionData: action.payload };
        case GET_TERMS_CONDITION_DATA_FAILURE:
            return { ...state, loading: false, termsConditionData: action.payload };
        default:
            return state;
    }
};