import { all, takeEvery, put } from 'redux-saga/effects';

import { GET_RANDOM_SEARCH_DATA, GET_ADD_ON_DATA } from '../action/types';
import {
    getRandomSearchDataSuccess,
    getRandomSearchDataFailure,
    getAddOnDataSuccess,
    getAddOnDataFailure
} from '../action';
import API from '../../utils/api';

function* getRandomSearchDataRequest(action) {
    try {
        const res = yield API.post('search', action.payload);
        if(res.data.meta.code === 1) {
            yield put(getRandomSearchDataSuccess(res.data.data));
        }
    } catch (e) {
        yield put(getRandomSearchDataFailure(e));
    }
}

function* getAddOnDataRequest(action) {
    try {
        const res = yield API.post('home-search', {
            search: action.payload
        });
        if(res.data.meta.code === 1) {
            yield put(getAddOnDataSuccess(res.data.data));
        }
    } catch (e) {
        yield put(getAddOnDataFailure(e));
    }
}

export function* watchGetRandomSearchDataAPI() {
    yield takeEvery(GET_RANDOM_SEARCH_DATA, getRandomSearchDataRequest);
}

export function* watchGetAddOnDataAPI() {
    yield takeEvery(GET_ADD_ON_DATA, getAddOnDataRequest);
}

export default function* rootSaga() {
    yield all([
        watchGetRandomSearchDataAPI(),
        watchGetAddOnDataAPI()
    ]);
}