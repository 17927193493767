import { all, takeEvery, put, call } from 'redux-saga/effects';
import {toast} from "react-toastify";

import {FORGOT_PASSWORD} from '../action/types';
import { forgotPasswordSuccess, forgotPasswordFailure } from '../action';
import API from '../../utils/api';
import {setEmailInStorage} from "../../utils/helper";

function* forgotPasswordRequest(actions) {
    try {
        const res = yield API.post('forgot-password', {email: actions.payload.email});
        if(res.data.meta.code === 1){
            yield put(forgotPasswordSuccess());
            yield toast(res.data.meta.message);
            yield call(actions.payload.callback);
            yield call(setEmailInStorage, actions.payload.email);
        } else if(res.data.meta.code === 0) {
            yield put(forgotPasswordFailure());
            toast(res.data.meta.message);
        }
    }catch (e) {
        yield put(forgotPasswordFailure());
    }   
}

export function* watchForgotPasswordAPI() {
    yield takeEvery(FORGOT_PASSWORD, forgotPasswordRequest);
}

export default function* rootSaga() {
    yield all([
        watchForgotPasswordAPI()
    ]);
}