import { combineReducers } from 'redux';

import Login from './loginReducer';
import Register from './registerReducer';
import ForgotPassword from './forgotPasswordReducer';
import Support from './supportReducer';
import ResetPassword from './resetPasswordReducer';
import Careers from './careersReducer';
import Faq from './faqReducer';
import CategoryAndIndicators from './categoryAndIndicatorsReducer';
import Countries from './countriesReducer';
import MyProfile from './myProfileReducer';
import Home from './homeReducer';
import CountryDashboard from './countryDashboardReducer';
import CountryIndicator from './countryIndicatorReducer';
import RandomSearch from './randomSearchReducer';
import MyCharts from "./myChartsReducer";
import AboutUs from './aboutUsReducer';
import CMS from './cmsPageReducer';

const appReducer = combineReducers({
    Login,
    Register,
    ForgotPassword,
    Support,
    ResetPassword,
    Careers,
    CategoryAndIndicators,
    Faq,
    Countries,
    MyProfile,
    Home,
    CountryDashboard,
    RandomSearch,
    CountryIndicator,
    MyCharts,
    AboutUs,
    CMS,
});

const reducers = (state, action) => {
    return appReducer(state, action);
};

export default reducers;