import {useLayoutEffect, useState} from "react";

export const setToken = token => localStorage.setItem('token', token);

export const getToken = () => localStorage.getItem('token');

export const removeToken = () => localStorage.removeItem('token');

export const setEmailInStorage = email => localStorage.setItem('email', email);

export const setPasswordInStorage = password => localStorage.setItem('password', password);

export const getEmail = () => localStorage.getItem('email');

export const getPassword = () => localStorage.getItem('password');

export const url = process.env.REACT_APP_API_ENDPOINT;

export const numberWithCommas = (x) => {
    return new Intl.NumberFormat().format(x);
}

export const getUniqueListBy = (arr, key) => {
    return [...new Map(arr.map(item => [item[key], item])).values()]
}

export const useWindowSize = () => {
    const [size, setSize] = useState([0, 0]);
    useLayoutEffect(() => {
        function updateSize() {
            setSize([window.innerWidth, window.innerHeight]);
        }
        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
    }, []);
    return size;
}

export const capitalize = (s) => {
    if (typeof s !== 'string') return ''
    return s.charAt(0).toUpperCase() + s.slice(1)
}