import {
    GET_MY_CHARTS_DATA,
    GET_MY_CHARTS_DATA_SUCCESS,
    GET_MY_CHARTS_DATA_FAILURE,
} from '../action/types';

const INIT_STATE = {
    loading: false,
    data: [],
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_MY_CHARTS_DATA:
            return { ...state, loading: true };
        case GET_MY_CHARTS_DATA_SUCCESS:
            return { ...state, loading: false, data: action.payload };
        case GET_MY_CHARTS_DATA_FAILURE:
            return { ...state, loading: false, data: action.payload };
        default:
            return state;
    }
};