import React from 'react';
import {Line} from 'react-chartjs-2';
import PropTypes from 'prop-types';

const MiniMap = ({data}) => {
    const gradientChartOptionsConfiguration = {
        maintainAspectRatio: false,
        legend: {
            display: false
        },
        tooltips: {
            enabled: false
        },
        elements: {
            point:{
                radius: 0
            }
        },
        responsive: 1,
        scales: {
            yAxes: [{
                display:0,
                ticks: {
                    display: false
                },
                gridLines: {
                    zeroLineColor: "transparent",
                    drawTicks: false,
                    display: false,
                    drawBorder: false
                }
            }],
            xAxes: [{
                display:0,
                ticks: {
                    display: false
                },
                gridLines: {
                    zeroLineColor: "transparent",
                    drawTicks: false,
                    display: false,
                    drawBorder: false
                }
            }]
        },
        layout:{
            padding:{left:0,right:0,top:15,bottom:15}
        }
    }

    const state = (canvas) => {
        const ctx = canvas.getContext("2d");
        const gradient = ctx.createLinearGradient(0, 0, 0, 100);
        gradient.addColorStop(0, 'rgba(24, 42, 124,1)');
        gradient.addColorStop(1, 'rgba(24, 42, 124,0)');
        return{
            labels: data && Object.keys(data),
            datasets: data && typeof Object.values(data)[0] === "object" ? Object.values(data).map(itm => {
                return {
                    borderColor: "#182A7C",
                    fill: true,
                    backgroundColor: gradient,
                    data: Object.values(itm)
                }
            }) : [{
                borderColor: "#182A7C",
                fill: true,
                backgroundColor: gradient,
                data: data && Object.values(data)
            }]
        }
    }

    return (
        <Line
            data={state}
            options={gradientChartOptionsConfiguration}
        />
    );
};

MiniMap.propTypes = {
    data: PropTypes.object
};

export default MiniMap;