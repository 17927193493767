import { all, takeEvery, put } from 'redux-saga/effects';

import {GET_PRIVACY_POLICY_DATA, GET_TERMS_CONDITION_DATA} from '../action/types';
import {
    getPrivacyPolicyDataSuccess,
    getPrivacyPolicyDataFailure,
    getTermConditionDataSuccess,
    getTermConditionDataFailure
} from '../action';
import API from '../../utils/api';

function* getPrivacyPolicyDataRequest() {
    try {
        const res = yield API.get('cms/privacy-policy');
        if(res.data.meta.code === 1){
            yield put(getPrivacyPolicyDataSuccess(res.data.data));
        } else if(res.data.meta.code === 0) {
            yield put(getPrivacyPolicyDataFailure(res.data.data));
        }
    }catch (e) {
        yield put(getPrivacyPolicyDataFailure(e));
    }
}

function* getTermsConditionDataRequest() {
    try {
        const res = yield API.get('cms/terms-and-conditions');
        if(res.data.meta.code === 1){
            yield put(getTermConditionDataSuccess(res.data.data));
        } else if(res.data.meta.code === 0) {
            yield put(getTermConditionDataFailure(res.data.data));
        }
    }catch (e) {
        yield put(getTermConditionDataFailure(e));
    }
}

export function* watchGetPrivacyPolicyDataAPI() {
    yield takeEvery(GET_PRIVACY_POLICY_DATA, getPrivacyPolicyDataRequest);
}

export function* watchGetTermsConditionDataAPI() {
    yield takeEvery(GET_TERMS_CONDITION_DATA, getTermsConditionDataRequest);
}

export default function* rootSaga() {
    yield all([
        watchGetPrivacyPolicyDataAPI(),
        watchGetTermsConditionDataAPI()
    ]);
}