import { all, takeEvery, put } from 'redux-saga/effects';

import { GET_FAQ_LIST } from '../action/types';
import { getFAQListSuccess, getFAQListFailure } from '../action';
import API from '../../utils/api';

function* faqListRequest(action) {
    const { page, per_page } = action.payload;
    const url = `faq?page=${page}&per_page=${per_page}`;
    try {
        const res = yield API.get(url);
        if(res.data.meta.code === 1) {
            yield put(getFAQListSuccess(res.data));
        }
    } catch (e) {
        yield put(getFAQListFailure(e));
    }
}

export function* watchFaqListAPI() {
    yield takeEvery(GET_FAQ_LIST, faqListRequest);
}

export default function* rootSaga() {
    yield all([
        watchFaqListAPI()
    ]);
}