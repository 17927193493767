import axios from 'axios';

import { url, getToken } from './helper';

const api = axios.create({
    baseURL: url
});

api.interceptors.request.use(function (config) {
    config.headers.Authorization = `Bearer ${getToken()}`;
    return config;
}, function (error) {
    return Promise.reject(error);
});

api.interceptors.response.use((response) => {
    return response
}, function (error) {
    if (error.response.status === 401) {
        localStorage.removeItem('token')
        window.location.href = '/login';
        return Promise.reject(error);
    }
    return Promise.reject(error);
});


export default api;