import {
    GET_RANDOM_SEARCH_DATA,
    GET_RANDOM_SEARCH_DATA_SUCCESS,
    GET_RANDOM_SEARCH_DATA_FAILURE,

    GET_ADD_ON_DATA,
    GET_ADD_ON_DATA_SUCCESS,
    GET_ADD_ON_DATA_FAILURE,
    RESET_ADD_ON_DATA
} from './types';

export const getRandomSearchData = payload => ({
    type: GET_RANDOM_SEARCH_DATA,
    payload
});

export const getRandomSearchDataSuccess = (payload) => ({
    type: GET_RANDOM_SEARCH_DATA_SUCCESS,
    payload
});

export const getRandomSearchDataFailure = (payload) => ({
    type: GET_RANDOM_SEARCH_DATA_FAILURE,
    payload
});

export const getAddOnData = payload => ({
    type: GET_ADD_ON_DATA,
    payload
});

export const getAddOnDataSuccess = payload => ({
   type: GET_ADD_ON_DATA_SUCCESS,
   payload
});

export const getAddOnDataFailure = payload => ({
   type: GET_ADD_ON_DATA_FAILURE,
   payload
});

export const resetAddOnData = () => ({
   type: RESET_ADD_ON_DATA
});