import {
    GET_FAQ_LIST,
    GET_FAQ_LIST_SUCCESS,
    GET_FAQ_LIST_FAILURE,
    RESET_FAQ_LIST
} from '../action/types';

const INIT_STATE = {
    loading: false,
    data:[],
    pagination: {}
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_FAQ_LIST:
            return {...state, loading: true};
        case GET_FAQ_LIST_SUCCESS:
            return {...state, loading: false, data: state.data.concat(action.payload.data), pagination: action.payload.meta};
        case GET_FAQ_LIST_FAILURE:
            return {...state, loading: false, data: action.payload};
        case RESET_FAQ_LIST:
            return {...state, data: []};
        default:
            return state;
    }
};