import {
    GET_USER_PROFILE,
    GET_USER_PROFILE_SUCCESS,
    GET_USER_PROFILE_FAILURE,

    EDIT_PROFILE,
    EDIT_PROFILE_SUCCESS,
    EDIT_PROFILE_FAILURE,

    CHANGE_PASSWORD,
    CHANGE_PASSWORD_SUCCESS,
    CHANGE_PASSWORD_FAILURE
} from '../action/types';

const INIT_STATE = {
    loading: false,
    userData: {}
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_USER_PROFILE:
            return { ...state, loading: true };
        case GET_USER_PROFILE_SUCCESS:
            return { ...state, loading: false, userData: action.payload };
        case GET_USER_PROFILE_FAILURE:
            return { ...state, loading: false, userData: action.payload };
        case EDIT_PROFILE:
            return { ...state, loading: true };
        case EDIT_PROFILE_SUCCESS:
            return { ...state, loading: false };
        case EDIT_PROFILE_FAILURE:
            return { ...state, loading: false };
        case CHANGE_PASSWORD:
            return { ...state, loading: true };
        case CHANGE_PASSWORD_SUCCESS:
            return { ...state, loading: false };
        case CHANGE_PASSWORD_FAILURE:
            return { ...state, loading: false };
        default:
            return state;
    }
};