import {
    GET_CAREERS_LIST,
    GET_CAREERS_LIST_SUCCESS,
    GET_CAREERS_LIST_FAILURE,
    RESET_CAREERS_LIST
} from './types';

export const getCareersList = (payload) => ({
    type: GET_CAREERS_LIST,
    payload
});

export const getCareersListSuccess = (payload) => ({
    type: GET_CAREERS_LIST_SUCCESS,
    payload
});

export const getCareersListFailure = (payload) => ({
    type: GET_CAREERS_LIST_FAILURE,
    payload
});

export const resetCareersList = () => ({
    type: RESET_CAREERS_LIST
})
