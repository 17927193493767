import { all, takeEvery, put, call } from 'redux-saga/effects';
import {toast} from "react-toastify";

import {RESET_PASSWORD} from '../action/types';
import { resetPasswordSuccess, resetPasswordFailure } from '../action';
import API from '../../utils/api';
import {getEmail} from "../../utils/helper";

function* resetPasswordRequest(actions) {
    try {
        const res = yield API.post('reset-password', {
            verification_code: actions.payload.form.verificationCode,
            password: actions.payload.form.newPassword,
            email: getEmail()
        });
        if(res.data.meta.code === 1){
            yield put(resetPasswordSuccess());
            yield call(actions.payload.callback)
            toast(res.data.meta.message);
        } else if(res.data.meta.code === 0) {
            yield put(resetPasswordFailure());
            toast(res.data.meta.message);
        }
    }catch (e) {
        yield put(resetPasswordFailure());
    }
}

export function* watchResetPasswordAPI() {
    yield takeEvery(RESET_PASSWORD, resetPasswordRequest);
}

export default function* rootSaga() {
    yield all([
        watchResetPasswordAPI()
    ]);
}