import { all, takeEvery, put } from 'redux-saga/effects';

import {GET_COUNTRIES_LIST} from '../action/types';
import {getCountriesListSuccess, getCountriesListFailure} from '../action';
import API from '../../utils/api';

function* countriesListRequest(action) {
    try {
        const res = yield API.post('country', action.payload);
        if(res.data.meta.code === 1) {
            yield put(getCountriesListSuccess(res.data.data));
        } else {
            yield put(getCountriesListFailure(res.data.data));
        }
    } catch (e) {
        yield put(getCountriesListFailure(e));
    }
}

export function* watchCountriesListAPI() {
    yield takeEvery(GET_COUNTRIES_LIST, countriesListRequest);
}

export default function* rootSaga() {
    yield all([
        watchCountriesListAPI()
    ]);
}