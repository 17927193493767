import React from 'react';
import PropTypes from 'prop-types';

const InputTypeFileWithLabel = ({label, img, handleChange}) => {
    return (
        <>
            <input
                type="file"
                className='upload-input'
                accept="image/*"
                onChange={handleChange}
            />
            <img
                className="rounded-circle upload-img"
                alt="100*100"
                src={img}
                width={70}
                height={70}
            />
            <span className='f-14 text-grey ml-3'>{label}</span>
        </>
    );
};

InputTypeFileWithLabel.propTypes = {
    label: PropTypes.string,
    img: PropTypes.string,
    handleChange: PropTypes.func
};

export default InputTypeFileWithLabel;